@import '../../../styles/variables';
@import '../../../styles/global';
@import '../../../styles/media-queries';

.siteSegments {
  border-radius: 5px;
  background-color: $white;
  padding: 0px 30px 30px 30px;
  box-sizing: border-box;
  position: relative;

  .titleRows {
    padding-top: 30px;
    padding-left: 18px;
    padding-right: 15px;

    .circle {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 4px;
    }

    img {
      margin-right: 10px;
    }

    .rows {
      margin-left: 0px;
      min-height: 50px;
      width: 100%;
      @include font(3, 'regular');
      border-top: solid 1px $border;
      color: $textColor;
      align-items: center;
      padding-right: 15px;
      background-color: $white;
      cursor: pointer;
    }
    .noBorder {
      border-top: 0px !important;
    }

    .rows:hover {
      background-color: $lightBorder;
    }

    .link {
      cursor: pointer;
    }

    h3 {
      margin-right: 10px;
    }
    span {
      @include font(2, 'medium');
    }
    .pipeSeparator {
      border-right: solid 1px $grey;
    }
    .active {
      color: $darkBlue !important;
    }
    .nonActive {
      color: $grey !important;
    }
  }

  .alertsRow {
    padding-left: 18px;
    align-items: center;

    img {
      margin-right: 5px;
    }

    span {
      @include font(2, 'medium');
    }
  }

  .viewMap {
    width: 100%;

    .mapButton {
      width: 100% !important;
    }
  }

  .siteSegmentWrapper {
    padding: 0;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 20px;

    li {
      margin: 0 20px 20px 0;
      width: calc(33% - 30px);
      height: 250px;
      border-radius: 6px;
      box-sizing: border-box;
      background-color: $white;
      border-left: solid 6px $lightBlue;
    }
  }

  .btnSize {
    height: 36px;
  }

  @media (max-width: 1024px) {
    .siteSegmentWrapper {
      li {
        width: calc(100% - 10px) !important;
        margin: 0 0px 20px 0 !important;
      }
    }

    .viewMap {
      display: block !important;
    }
  }
}
