@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.sites {
  width: 100%;

  .dashboardContent {
    width: 100%;
    border-radius: 5px;
    background-color: $white;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    margin-top: 40px;

    .imageContent {
      text-align: center;
    }
  }

  .fullWidth {
    span {
      @include font(2);
      color: $grey !important;
    }
  }
  .img {
    height: 40vw;
  }
}
