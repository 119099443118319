@import './variables';
@import './global';

.btn {
  width: 160px;
  height: 50px;
  border-radius: 3px !important;
  background-color: $lightBlue;
  @include font(2, 'medium');
  color: $white;
  border: none !important;
  align-self: flex-start;
  text-transform: uppercase;

  &[disabled] {
    opacity: 0.5;
  }
}

.selectLink {
  @include font(3, 'bold');
  color: $darkBlue;
  margin-top: 20px;
  display: block;
}

.btn-primary {
  color: $white !important;
  background-color: $lightBlue !important;
  border-color: none;
}

.btn-primary:hover {
  background-color: $darkBlue !important;
}

.btn-outline-secondary {
  border: solid 1px $lightBlue !important;
  height: 36px;
  @include font(2, 'medium');
  color: $lightBlue !important;
  background-color: $white !important;
}

.btn-outline-secondary:hover {
  background-color: $lighterBlue !important;
}

.btn-outline-danger {
  border: solid 1px $danger !important;
  height: 36px;
  @include font(2, 'medium');
  color: $danger !important;
  background-color: $white !important;
}

.btn-danger {
  background-color: $danger !important;
  color: $white !important;
}

.btn-danger:hover {
  background-color: $dangerHover !important;
}

.btn-success {
  background-color: $success !important;
  color: $white !important;
}

.btn-success:hover {
  background-color: $successHover !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

select:focus {
  outline: none !important;
  option:focus {
    outline: none !important;
  }
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

.form-label {
  @include font(2, 'medium');
}

.form-control {
  @include font(2);
  border: 1px solid $border !important;
  margin-bottom: 20px;
  height: 50px !important;
}

.form-control:focus {
  border-color: $lightBlue !important;
  box-shadow: none !important;
}

.has-icon .form-control {
  padding-left: 1.375rem;
}
.has-icon .form-control-icon {
  position: absolute;
  z-index: 0;
  display: block;
  width: 2.375rem;
  height: 50px;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding-top: 6px;
  right: 20px;
}

.has-icon .form-control-icon {
  color: $darkBlue;
}
.h1,
h1 {
  @include font(7);
  color: $lightBlue !important;
}

.h2,
h2 {
  @include font(6);
  color: $darkBlue !important;
}

.h3,
h3 {
  @include font(5);
  color: $darkBlue !important;
}

.h4,
h4 {
  @include font(4);
  color: $darkBlue !important;
}

.h5,
h5 {
  @include font(3);
  color: $darkBlue !important;
}

.h6,
h6 {
  @include font(2, 'medium');
  color: $darkBlue !important;
}

.alert-light {
  @include font(2);
  background-color: $errorBackground !important;
  border-color: $danger !important;
}

.alert-warning {
  @include font(2);
  background-color: $warning !important;
  border-color: $alert !important;
}

.alert {
  border-radius: 0.25rem;

  .close {
    font-size: 30px;
    font-weight: normal;
    line-height: 1;
    color: $white;
    opacity: 1;
    padding-top: 6px !important;
  }

  .close:hover {
    color: $white !important;
  }
}

.errorMsg {
  @include font(2);
  color: $error !important;
}

.alert-success {
  @include font(2, 'semi-bold');
  color: $white !important;
  background-color: $success !important;

  img {
    margin-right: 10px;
  }
}

.alert-danger {
  @include font(2, 'semi-bold');
  color: $white !important;
  background-color: $danger !important;

  img {
    margin-right: 10px !important;
  }
}

.btn-secondary {
  width: 100%;
  height: 48px;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.25px;
  text-align: center;
  color: #ffffff;
}

.table {
  td,
  th {
    padding: 1.25rem;
    vertical-align: top;
    border-top: none;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  thead th {
    padding-bottom: 5px;
    border-bottom: none;
    color: #919191;
    border-top: none !important;
    height: 50px;
  }
  tbody td {
    vertical-align: middle;
    border-bottom: none;
    background-color: white;
    height: 50px;
  }
  tbody tr {
    // display: block;
    border: 8px solid #f7f6f6;
    border-left: solid 6px $lightBlue;
  }
  thead th,
  tbody td {
    padding-left: 30px;
  }

  tr td:first-child {
    // border-radius: 20px 0px 0px 20px;
    font-weight: 500;
    color: #3d3d41;
  }
  tr td:last-child {
    border-radius: 0px 20px 20px 0px;
  }
}
