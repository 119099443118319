@import '../../../styles/variables';
@import '../../../styles/global';

.safetyPlan {
  width: 100%;
  @include font(2);

  .tabHeader {
    padding-bottom: 20px;
  }
  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .btnRow {
      margin-top: 10px;
    }

    .btnSaveChanges {
      width: 230px !important;
      margin-bottom: 5px !important;
    }

    .link {
      color: $textColor;
      text-decoration: none;
    }

    .btnCancel {
      width: 230px;
      margin: 0px 5px;
      height: 50px !important;
    }

    .breakRow {
      padding: 30px 0px;
    }

    .safetyPlanHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .safetyPlanContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      margin-top: 40px;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }
      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .smallText {
        width: 150px;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }
    }

    .safetyPlanTypeContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      box-sizing: border-box;
      position: relative;
      margin-top: 30px;
      padding-bottom: 30px;

      .btnSaveChangesSmall {
        width: 100px !important;
        height: 36px !important;
      }

      .btnMedium {
        width: 150px !important;
        height: 36px !important;
        float: right;
      }

      .addbutton {
        width: 194px !important;
        float: right;
      }

      .btnCancelSmall {
        width: 100px;
        margin: 0px 5px;
        height: 36px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlSmall {
        margin-bottom: 0px;
        max-width: 90px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }

      .wsaDropdown {
        width: 100%;
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        div[class*='menu-list'] {
          div[class*='option'] {
            border-bottom: solid 1px $border;
          }
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }
      }

      .contentHolder {
        padding: 30px;

        .topRow {
          padding-bottom: 30px;
        }
        .subheading {
          padding-top: 10px;
        }
      }

      .grpItem {
        margin-bottom: 30px;
      }

      .grpHeading {
        padding-left: 30px;
        padding-right: 30px;
      }
      .newRow {
        padding: 30px;
      }

      @media (max-width: 768px) {
        .addbutton {
          width: 100% !important;
        }
      }
    }
  }
}

.safetyPlanList {
  width: 100%;
  @include font(2);

  .tabHeader {
    padding-bottom: 20px;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }
  .addbutton {
    float: right;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}
