@import './variables';
@import './global';

:global {
  .rdtPicker {
    z-index: 5 !important;

    td.rdtActive,
    td.rdtActive:hover {
      background-color: $lightBlue;
    }
    td.rdtToday:before {
      border-bottom: 7px solid $lightBlue;
    }
  }
}

@mixin sharedInputStyle {
  min-height: 44px;
  background-color: $white;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%230081b3%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 11px 11px;
  box-sizing: border-box;
  border: 1px solid $border;
  margin: 6px 30px 0 0;
}

#eventLogExportBtn {
  margin-left: auto;
}

.inputWrapper {
  display: flex;
  margin-bottom: 40px;
  align-items: baseline;

  label {
    @include font(1);
  }

  input,
  select {
    display: block;
    @include font(3);
    line-height: initial;
    width: 230px;
    color: $textColor;
    padding: 0.6em 1.4em 0.5em 0.8em;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    @include sharedInputStyle();

    &::-ms-expand {
      display: none;
    }
  }

  .multiSelect {
    :global {
      .multi__control {
        width: 300px;
        @include sharedInputStyle();
      }
      .multi__indicators {
        display: none;
      }
      .multi__option {
        @include font(2);
      }
      input {
        min-height: 0;
        margin: auto;
        background-color: $lightBlue;
      }
    }
  }

  input[type='number'] {
    background-image: none;
    width: 90px;
  }
  input[type='text'] {
    background-image: none;
  }
}
