@import '../../../styles/variables';
@import '../../../styles/global';
@import '../../../styles/media-queries';

.titleRows {
  padding-top: 30px;
  padding-left: 18px;

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 4px;
  }

  img {
    margin-right: 10px;
  }

  h3 {
    margin-right: 10px;
  }
}
