@import '../../styles/variables';
@import '../../styles/global';

.inspectionReport {
  width: 100%;
  @include font(2);

  .fullWidth {
    .fullWidthWrapper {
      flex-direction: column;
    }
  }

  .pageContent {
    margin: 0px;
  }

  .tabHeader {
    padding-bottom: 20px;
  }

  .breakrow {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 20px;
    border-top: 1px solid $border;
  }

  .inspectionReportHeader {
    justify-content: space-between;
    margin-bottom: 20px;

    .backLink {
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-bottom: 15px;
      display: block;
    }
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .textArea {
      height: auto !important;
    }

    .inspectionReportContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .dueDateTime {
        max-width: 200px;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
    }
  }

  .inpsectionReportQuestions {
    width: 100%;
    border-radius: 5px;

    background-color: $white;
    padding: 40px 0px;
    box-sizing: border-box;
    position: relative;

    .ddlError > div {
      border: 1px solid $error;
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .ddlError > div {
      border: 1px solid $error;
    }

    .formControl {
      margin-bottom: 0px;
    }

    .formControlError {
      border: 1px solid $error !important;
    }
    .standardRow {
      padding-right: 40px;
      padding-left: 40px;
    }

    .padleft {
      padding-left: 10px;
    }

    .dot {
      height: 12px;
      width: 12px;
      background-color: $success;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
    .lowRisk {
      background-color: $success !important;
    }
    .mediumRisk {
      background-color: $yellow !important;
    }
    .highRisk {
      background-color: $alert !important;
    }
    .veryHighRisk {
      background-color: $danger !important;
    }

    .innerTable {
      vertical-align: top;
      border-top: none;
      font-size: 15px;
      width: 100%;
      margin-top: 20px;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        font-weight: 500;
        background-color: $border;
        border: none !important;
        color: $textColor;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }
      .textConstInput {
        width: 100px;
        margin-bottom: 0px;
      }
      .textInput {
        width: 100%;
        height: 50px !important;
        @include font(2);
        line-height: 1.5rem !important;
        color: $textColor !important;
        border: 1px solid $border !important;
        margin-bottom: 0px;
        border-radius: 4px;
        padding: 2px 8px;
      }
      tbody tr {
        border: none;
      }

      .questionText {
        font-weight: 600;
        padding-bottom: 10px;
      }

      .normalItalicText {
        font-weight: normal;
        font-style: italic;
      }

      .questionComment {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .commentText {
        width: 60%;
      }

      .commentButton {
        color: $darkBlue;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
      }

      tr td:first-child {
        font-weight: normal;
      }

      tbody tr td {
        border-top: 1px solid $border;
      }

      .emptyCol {
        width: 40%;
      }
      .padleft {
        padding-left: 10px;
      }
      .switchContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      .switch {
        margin-left: 0px;
      }
    }

    .newRow {
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
    }
  }
}

.inspectionReportList {
  width: 100%;
  @include font(2);

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 8px;
    margin-left: 10px;
  }

  .titleRows {
    padding-top: 30px;
    padding-left: 18px;
    padding-right: 15px;

    .innerComponent {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      box-sizing: border-box;
      position: relative;

      .component {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    img {
      margin-right: 10px;
    }

    h4 {
      padding-top: 5px;
    }
    .link {
      cursor: pointer;
    }
  }

  .topRow {
    padding: 30px;
  }

  .topAndSideOnlyRow {
    padding: 30px 30px 0px 30px;
  }
  .sideRow {
    padding: 0px 30px;
    padding-bottom: 30px;
  }

  .margin5 {
    margin: 0px 5px;
  }
  .btnSaveChanges {
    width: 100px !important;
    height: 36px !important;
  }

  .addbutton {
    float: right;
  }

  .btnCancel {
    width: 100px;
    margin: 0px 5px;
    height: 36px !important;
  }
  .error {
    @include font(2);
    color: $error !important;
  }
  .ddlError > div {
    border: 1px solid $error;
  }

  .table {
    tbody tr {
      cursor: pointer;
      // margin: 0 20px 20px 0;
      // border-collapse: separate;
      // border-left: solid 6px $danger;
    }

    thead th {
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
    }

    .empty {
      width: 100px;
      padding-left: 0px;
      background-color: $lightBlue;
    }

    .dot {
      height: 12px;
      width: 12px;
      background-color: $success;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .lowRisk {
      background-color: $success !important;
    }
    .mediumRisk {
      background-color: $yellow !important;
    }
    .highRisk {
      background-color: $alert !important;
    }
    .veryHighRisk {
      background-color: $danger !important;
    }

    .lowRiskBorder {
      border-left: solid 6px $success !important;
    }
    .mediumRiskBorder {
      border-left: solid 6px $yellow !important;
    }
    .highRiskBorder {
      border-left: solid 6px $alert !important;
    }
    .veryHighRiskBorder {
      border-left: solid 6px $danger !important;
    }

    .sortIcon {
      height: 12.2px;
      width: 10px;
      margin-left: 8px;
    }
    .sortHeading {
      cursor: pointer;
    }
    .changeButton {
      width: 100px;
    }
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }

  .newRow {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
  // }
}

.btnSaveChanges {
  width: 190px !important;
}

.btnCancel {
  width: 190px;
  margin: 0px 5px;
  height: 50px !important;
}
