@import '../../../../styles/variables';
@import '../../../../styles/global';

.grey {
  fill: $infinityGrey;
  stroke: $white;
  stroke-miterlimit: 10;
  cursor: pointer;
  &:hover {
    fill: $infinityGreyHover;
  }
}

.red {
  fill: $infinityRed;
  stroke: $white;
  stroke-miterlimit: 10;
  cursor: pointer;
  &:hover {
    fill: $infinityRedHover;
  }
}

.lightBlue {
  fill: $infinityLightBlue;
  stroke: $white;
  stroke-miterlimit: 10;
  cursor: pointer;
  &:hover {
    fill: $infinityLightBlueHover;
  }
}

.darkBlue {
  fill: $infinityDarkBlue;
  stroke: $white;
  stroke-miterlimit: 10;
  cursor: pointer;
  &:hover {
    fill: $infinityDarkBlueHover;
  }
}

.amber {
  fill: $infinityAmber;
  stroke: $white;
  stroke-miterlimit: 10;
  cursor: pointer;
  &:hover {
    fill: $infinityAmberHover;
  }
}

.border {
  fill: $white;
}
