@import '../../../styles/variables';
@import '../../../styles/global';

.improvementPlan {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .improvementPlanHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .textArea {
      height: auto !important;
    }

    .btnSaveChanges {
      width: 190px !important;
    }

    .btnCancel {
      width: 190px;
      margin: 20px 10px;
      height: 50px !important;
    }

    .improvementPlanMainInfo {
      padding: 40px;
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .ddlError > div {
      border: 1px solid $error;
    }

    .formControl {
      margin-bottom: 0px;
    }

    .formControlError {
      border: 1px solid $error !important;
    }

    .spacedRow {
      padding: 35px 25px;
    }

    .viewRow {
      padding-top: 20px !important ;
    }

    .colActionButton {
      float: right;
      margin-right: 0px;
      align-items: center;
    }

    .improvementPlanComment {
      width: 100%;
      border-radius: 5px;
      padding-top: 20px;
      background-color: $white;
      box-sizing: border-box;
      position: relative;

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 4px;
        margin-left: 10px;
      }
    }

    .improvementPlanContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      margin-top: 40px;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }
    }
  }
}

.riskCategoryContent {
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  background-color: $white;
  box-sizing: border-box;
  position: relative;

  .intro {
    @include font(3);
    margin-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }

  .borderBox {
    border: 1px solid $border;
    margin-left: 15px;
    margin-bottom: 40px;
  }

  .error {
    @include font(2);
    color: $error !important;
  }

  .formControl {
    margin-bottom: 0px;
  }

  .formControlError {
    border: 1px solid $error !important;
  }

  .spacedRow {
    padding: 35px 25px;
  }

  .innerTable {
    padding: 1.25rem;
    vertical-align: top;
    font-size: 15px;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 40px !important;
    font-weight: 500;

    thead th {
      padding-bottom: 5px;
      background-color: $border;
      border: 1px solid $headerBorder !important;
      color: $textColor !important;
      font-weight: 500;
      height: 40px;
      vertical-align: middle !important;
      padding-top: 5px !important;
    }

    thead th p {
      @include font(1);
    }

    .tdGreen {
      cursor: pointer;
      background-color: $success;
    }

    .tdYellow {
      cursor: pointer;
      background-color: $yellow;
    }

    .tdOrange {
      cursor: pointer;
      background-color: $alert;
    }

    .tdRed {
      cursor: pointer;
      background-color: $danger;
    }

    .cursorNone {
      cursor: default !important;
    }

    .normalItalicText {
      font-weight: normal;
      font-style: italic;
      @include font(1);
    }

    tbody tr {
      border: 1px solid $border;
    }

    tr td:first-child {
      color: $textColor !important;
      font-weight: 500;
      padding-left: 30px;
      text-align: left;
    }

    tr td:last-child {
      border-radius: 0;
    }
    tbody tr td {
      border: 1px solid $border;
    }

    .btnCol {
      text-align: right;
    }

    .blueTick {
      float: right;
      height: 20px;
      margin-top: -15px;
    }

    .riskScoring {
      height: 70px;
      border: none;

      .ratingSpace {
        padding: 0px 4px;
      }
    }

    .riskScoring td {
      padding-left: 10px;
      color: $textColor !important;
      font-weight: 500;
      vertical-align: middle;
      border: 1px solid $border;
      text-align: center;
    }
  }

  .newRow {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
  }

  .formCheck {
    .container {
      display: inline;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      @include font(2);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-right: 30px;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $white;
      border-radius: 50%;
      border: solid 1px $border;
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: $lightBlue;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $white;
    }
  }
}

.improvementPlanList {
  width: 100%;
  @include font(2);

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }

  .addbutton {
    float: right;
  }
  .removeButton {
    float: right;
    width: 102px;
    margin-right: 10px;
  }
  .viewButton {
    width: 102px;
    margin-right: 8px;
  }

  .btnCol {
    text-align: right;
    padding-left: 0px;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }

  .sortHeading {
    cursor: pointer;
  }

  .lowRiskBorder {
    border-left: solid 6px $success !important;
  }
  .mediumRiskBorder {
    border-left: solid 6px $yellow !important;
  }
  .highRiskBorder {
    border-left: solid 6px $alert !important;
  }
  .veryHighRiskBorder {
    border-left: solid 6px $danger !important;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
  // }
}

.tabHeader {
  padding-bottom: 20px;
}
