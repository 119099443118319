@import '../../styles/variables';
@import '../../styles/global';

.waterSupplyDescription {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .btnRow {
      margin-top: 10px;
    }

    .btnSaveChanges {
      width: 230px !important;
      margin-bottom: 5px !important;
    }

    .link {
      color: $textColor;
      text-decoration: none;
    }

    .btnCancel {
      width: 230px;
      margin: 0px 5px;
      height: 50px !important;
    }

    .breakRow {
      padding: 30px 0px;
    }

    .waterSupplyDescriptionHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .waterSupplyDescriptionContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      margin-top: 40px;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }
      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .smallText {
        width: 150px;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }
    }
  }
}

.waterSupplyDescriptionList {
  width: 100%;
  @include font(2);

  .fullWidth {
    margin-bottom: 10px;
    .fullWidthWrapper {
      flex-direction: column;
    }
  }
  .contentWrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }

  .addbutton {
    float: right;
  }
  .changeButton {
    float: right;
    height: 35px;
    width: 100px;
    margin-bottom: 0px;
    margin-left: 10px;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}
