@import '../../styles/variables';
@import '../../styles/global';

.controlMonitoring {
  width: 100%;
  @include font(2);

  .tabHeader {
    padding-bottom: 20px;
  }
  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .btnRow {
      margin-top: 10px;
    }

    .btnSaveChanges {
      width: 230px !important;
      // margin-bottom: 5px !important;
    }

    .link {
      color: $textColor;
      text-decoration: none;
    }

    .btnCancel {
      width: 230px;
      margin: 0px 5px;
      height: 50px !important;
    }

    .breakRow {
      padding: 30px 0px;
    }

    .colActionButton {
      float: right;
      margin-right: 0px;
      align-items: center;
    }

    .controlMonitoringHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .innerTable {
      margin-top: -20px;

      .btnCol {
        text-align: right;
      }

      .removeButton {
        float: right;
        width: 100px;
        margin-right: 20px;
      }

      .sortIcon {
        height: 12.2px;
        width: 10px;
        margin-left: 8px;
      }
      .sortHeading {
        cursor: pointer;
      }
    }

    .controlMonitoringTypeContent {
      width: 100%;
      border-radius: 5px;
      // background-color: $white;
      box-sizing: border-box;
      position: relative;
      margin-top: 50px;
      // padding-bottom: 30px;

      .btnMedium {
        width: 150px !important;
        height: 36px !important;
        float: right;
      }

      .addbutton {
        width: 194px !important;
        float: right;
      }

      .subheading {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }

      .contentHolder {
        padding: 30px;

        .topRow {
          padding-bottom: 30px;
        }
      }

      .btnSaveChangesSmall {
        width: 100px !important;
        height: 36px !important;
      }

      .btnCancelSmall {
        width: 100px;
        margin: 0px 5px;
        height: 36px !important;
      }

      .grpItem {
        margin-bottom: 30px;
      }

      .grpHeading {
        padding-left: 30px;
        padding-right: 30px;
      }

      @media (max-width: 768px) {
        .addbutton {
          width: 100% !important;
        }
      }
    }

    .controlMonitoringContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      margin-top: 40px;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }
    }
  }

  .error {
    @include font(2);
    color: $error !important;
  }

  .ddlError > div {
    border: 1px solid $error;
  }

  .smallText {
    width: 150px;
  }

  .formControl {
    margin-bottom: 0px;
  }

  .formControlError {
    border: 1px solid $error !important;
  }
}

.controlMonitoringList {
  width: 100%;
  @include font(2);

  .tabHeader {
    padding-bottom: 20px;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }
  .addbutton {
    float: right;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }

  .btnCol {
    text-align: right;
  }

  .changeButton {
    width: 100px;
    margin-right: 10px;
    float: right;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}
