@import '../../styles/variables';
@import '../../styles/global';

.hazardEventTasksTemplates {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    // margin-top: 20px;
    margin-bottom: 20px;

    button {
      // @include font(3, 'bold');
      color: $lightBlue;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .addbutton {
      padding-top: 9px !important;
      width: 180px !important;
    }

    .addbuttonCol {
      text-align: right;
    }

    .circle {
      display: inline-flex;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 8px;
      margin-left: 10px;
    }

    .titleRows {
      padding-top: 30px;
      padding-left: 18px;
      padding-right: 15px;

      img {
        margin-right: 10px;
      }

      h4 {
        padding-top: 5px;
      }
      .link {
        cursor: pointer;
      }
    }

    .innerComponent {
      width: 100%;
      border-radius: 6px;
      margin-top: 20px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      box-sizing: border-box;
      position: relative;

      .component {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .viewButton {
        width: 102px;
        margin-right: 20px;
      }
      .removeButton {
        float: right;
        width: 102px;
        margin-right: 20px;
      }
      .topRow {
        padding: 30px;
      }
      .innerTable {
        padding: 1.25rem;
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        width: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        thead th {
          padding-bottom: 5px;
          background-color: $border;
          border: none !important;
          color: $textColor;
          height: 40px;
          vertical-align: middle !important;
          padding-top: 5px !important;
        }

        tbody tr {
          border: none;
        }

        tr td:first-child {
          font-weight: normal;
        }

        tbody tr td {
          border-top: 1px solid $border;
        }

        .btnCol {
          text-align: right;
        }
      }
    }

    @media (max-width: 768px) {
    }
  }
}

.hazardEventTemplate {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .hazardEventTemplateHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .error {
      @include font(2);
      color: $error !important;
    }

    .btnSaveChanges {
      width: 230px;
      margin: 0px 5px 0px 0px !important;
    }

    .btnCancel {
      width: 230px;
      height: 50px !important;
    }

    .colChangeButton {
      text-align: right;
    }

    .itemsContainer {
      margin-top: 50px;

      .colAddButton {
        text-align: right;
      }

      .ddlItemTemplate {
        max-width: 350px !important;
      }

      .itemRow {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .emptyRow {
        text-align: center;
        border-left: 0px !important;
      }

      .colBtn {
        text-align: right;
      }

      .itemRemoveButton {
        max-width: 100px;
        margin-top: 10px;
        margin-right: 15px;
        margin-bottom: 15px;
        float: right;
      }

      .itemButton {
        margin-right: 15px;
        height: 36px !important;
      }

      .subHeading {
        margin-bottom: 20px;
      }

      h4 {
        display: inline;
      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }
    }
    .hazardEventTemplateContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 20px;

      .margin5 {
        margin: 0px 5px;
      }

      .subHeading {
        @include font(4);
        color: $darkBlue !important ;
        margin-bottom: 30px;
      }

      .txtArea {
        width: 100%;
        height: 100px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .viewRow {
        padding-top: 20px !important ;
      }
    }
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    .btnCancel {
      width: 120px !important;
      font-size: 12px !important;
    }
    .btnSaveChanges {
      width: 120px !important;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
}
