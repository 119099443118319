@import '../../../styles/variables';
@import '../../../styles/global';

.stakeholderRegister {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .stakeholderRegisterHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .textArea {
      height: auto !important;
    }

    .stakeholderRegisterContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .btnSaveChanges {
        width: 190px !important;
      }

      .btnCancel {
        width: 190px;
        margin: 0px 5px;
        height: 50px !important;
      }
      .btnDelete {
        float: right;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
    }
  }
}

.stakeholderRegisterList {
  width: 100%;
  @include font(2);

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }

  .addbutton {
    float: right;
  }

  .table {
    tbody tr {
      cursor: pointer;
      // margin: 0 20px 20px 0;
      // border-collapse: separate;
      // border-left: solid 6px $danger;
    }

    thead th {
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
    }
    .sortIcon {
      height: 12.2px;
      width: 10px;
      margin-left: 8px;
    }
    .sortHeading {
      cursor: pointer;
    }
    .opButton {
      width: 100px;
      float: right !important;
      margin-right: 20px;
    }

    .normalItalicText {
      font-weight: normal;
      font-style: italic;
      width: 30%;
    }
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
  // }
}
