@import '../../../styles/variables';
@import '../../../styles/global';

.wrapper {
  margin-bottom: 10px;
  margin-top: 10px;

  .container {
    display: inline-flex;
    float: left;
    align-content: space-between;

    .crumbItem {
      @include font(2, 'medium');
      color: $infinityGrey !important;
    }
    .crumbItem a {
      color: $darkBlue;
    }

    .pad5 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .segmentTitle {
    @include font(3, 'medium');
    color: $darkBlue !important;
    display: flex;
    justify-content: flex-end;
  }
}
