@import '../../../styles/variables';
@import '../../../styles/global';

.inputWrapper {
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: block;
    width: 8px;
    color: #333;
    text-align: center;
    position: relative;
  }
  input[type='number']:hover::-webkit-inner-spin-button {
    background: #eee url('../../../images/inputArrows.png') no-repeat 50% 50%;
    width: 14px;
    height: 20px;
    padding: 4px 1px;
    position: relative;
  }
}

.inputWrapperNoNumber {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  input[type='number'] {
    -moz-appearance: textfield !important;
  }
}

.wsaInput:focus {
  border-color: $lightBlue !important;
  outline: 0 !important;
}

.wsaInput {
  width: 100%;
  height: 50px !important;
  @include font(2);
  line-height: 1.5rem !important;
  color: $textColor !important;
  border: 1px solid $border !important;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 2px 8px;
}
