@import '../../styles/variables';
@import '../../styles/global';

.testcategory {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .btnSaveChanges {
      width: 230px !important;
    }

    .btnCancel {
      width: 230px;
      margin: 0px 5px;
      height: 50px !important;
    }

    .breakRow {
      padding: 30px 0px;
    }

    .testcategoryHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .testcategoryContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }
      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }
    }

    .testcategoryTypeContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      box-sizing: border-box;
      position: relative;
      margin-top: 30px;
      padding-bottom: 40px;

      .btnSaveChangesSmall {
        width: 100px !important;
        height: 36px !important;
      }

      .btnCancelSmall {
        width: 100px;
        margin: 0px 5px;
        height: 36px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }

      .wsaDropdown {
        width: 100%;
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        div[class*='menu-list'] {
          div[class*='option'] {
            border-bottom: solid 1px $border;
          }
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }
      }

      .contentHolder {
        padding: 30px;

        .topRow {
          padding-bottom: 30px;
        }

        .subheading {
          padding-top: 10px;
        }

        .addbutton {
          width: 194px !important;
          float: right;
        }
      }
      .innerTable {
        padding: 1.25rem;
        vertical-align: top;
        border-top: none;
        font-size: 15px;
        width: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        thead th {
          padding-bottom: 5px;
          background-color: $border;
          border: none !important;
          color: $textColor;
          height: 40px;
          vertical-align: middle !important;
          padding-top: 5px !important;
        }

        tbody tr {
          border: none;
        }

        tr td:first-child {
          font-weight: normal;
        }

        tbody tr td {
          border-top: 1px solid $border;
        }
        tbody tr:last-child td {
          border-bottom: 1px solid $border;
        }

        .buttonContainer {
          display: flex;
          justify-content: flex-end;
        }
        .changeButton {
          float: right;
          width: 100px;
          margin-right: 20px;
        }
        .removeButton {
          float: right;
          width: 100px;
          margin-right: 20px;
        }

        .icon {
          cursor: pointer;
        }
      }

      .newRow {
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
      }

      @media (max-width: 768px) {
        .addbutton {
          width: 100% !important;
        }
      }
    }
  }
}

.testCategoryList {
  width: 100%;
  @include font(2);

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 8px;
    margin-left: 10px;
  }

  .titleRows {
    padding-top: 30px;
    padding-left: 18px;
    padding-right: 15px;

    .innerComponent {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      box-sizing: border-box;
      position: relative;

      .component {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    img {
      margin-right: 10px;
    }

    h4 {
      padding-top: 5px;
    }
    .link {
      cursor: pointer;
    }
  }

  .topRow {
    padding: 30px;
  }

  .subSection {
    padding: 30px 30px 0px 30px;
  }

  .topAndSideOnlyRow {
    padding: 30px 30px 0px 30px;
  }
  .sideRow {
    padding: 0px 30px;
    padding-bottom: 30px;
  }

  .margin5 {
    margin: 0px 5px;
  }
  .btnSaveChanges {
    width: 100px !important;
    height: 36px !important;
  }

  .addbutton {
    float: right;
  }
  .changeButton {
    float: right;
    height: 35px;
    width: 100px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .btnCancel {
    width: 100px;
    margin: 0px 5px;
    height: 36px !important;
  }
  .error {
    @include font(2);
    color: $error !important;
  }
  .ddlError > div {
    border: 1px solid $error;
  }

  .innerTable {
    padding: 1.25rem;
    vertical-align: top;
    border-top: none;
    font-size: 15px;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 0px !important;

    thead th {
      padding-bottom: 5px;
      background-color: $border;
      border: none !important;
      color: $textColor;
      height: 40px;
      vertical-align: middle !important;
      padding-top: 5px !important;
    }

    tbody tr {
      border: none;
    }

    tr td:first-child {
      font-weight: normal;
    }

    tbody tr td {
      border-top: 1px solid $border;
    }

    tbody tr:last-child td {
      border-bottom: 1px solid $border;
    }

    .btnCol {
      text-align: right;
    }
    .changeButton {
      margin-top: 10px;
      max-width: 100px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .removeButton {
      margin-top: 10px;
      float: right;
      max-width: 100px;
      margin-right: 20px;
    }
  }
  .newRow {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}
