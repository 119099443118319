@import '../../styles/variables';
@import '../../styles/global';

.generalFieldType {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .generalFieldTypeHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .textArea {
      height: auto !important;
    }

    .generalFieldTypeContent {
      width: 100%;
      border-radius: 5px;

      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }

      .btnSaveChanges {
        width: 190px !important;
      }

      .btnCancel {
        width: 190px;
        margin: 0px 5px;
        height: 50px !important;
      }
      .btnDelete {
        float: right;
        height: 50px !important;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }
    }
  }
}

.generalFieldTypeList {
  width: 100%;
  @include font(2);

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    border: solid 1px $darkBlue;
    @include font(2, 'medium');
    color: $darkBlue !important;
    margin-top: 8px;
    margin-left: 10px;
  }

  .titleRows {
    padding-top: 30px;
    padding-left: 18px;
    padding-right: 15px;

    .innerComponent {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      background-color: $white;
      box-sizing: border-box;
      position: relative;

      .component {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    img {
      margin-right: 10px;
    }

    h4 {
      padding-top: 5px;
    }
    .link {
      cursor: pointer;
    }
  }

  .topRow {
    padding: 30px;
  }

  .topAndSideOnlyRow {
    padding: 30px 30px 0px 30px;
  }
  .sideRow {
    padding: 0px 30px;
    padding-bottom: 30px;
  }

  .margin5 {
    margin: 0px 5px;
  }
  .btnSaveChanges {
    width: 100px !important;
    height: 36px !important;
  }

  .addbutton {
    float: right;
  }

  .btnCancel {
    width: 100px;
    margin: 0px 5px;
    height: 36px !important;
  }
  .error {
    @include font(2);
    color: $error !important;
  }
  .ddlError > div {
    border: 1px solid $error;
  }

  .table {
    tbody tr {
      cursor: pointer;
      // margin: 0 20px 20px 0;
      // border-collapse: separate;
      // border-left: solid 6px $danger;
    }

    thead th {
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grey;
    }

    .empty {
      width: 100px;
      padding-left: 0px;
      background-color: $lightBlue;
    }

    .dot {
      height: 12px;
      width: 12px;
      background-color: $success;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .lowRisk {
      background-color: $success !important;
    }
    .mediumRisk {
      background-color: $yellow !important;
    }
    .highRisk {
      background-color: $alert !important;
    }
    .veryHighRisk {
      background-color: $danger !important;
    }

    .lowRiskBorder {
      border-left: solid 6px $success !important;
    }
    .mediumRiskBorder {
      border-left: solid 6px $yellow !important;
    }
    .highRiskBorder {
      border-left: solid 6px $alert !important;
    }
    .veryHighRiskBorder {
      border-left: solid 6px $danger !important;
    }

    .sortIcon {
      height: 12.2px;
      width: 10px;
      margin-left: 8px;
    }
    .sortHeading {
      cursor: pointer;
    }
    .opButton {
      width: 100px;
      float: right !important;
      margin-right: 20px;
    }
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }

  .newRow {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
  // }
}
