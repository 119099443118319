@import '../../styles/variables';
@import '../../styles/global';

.riskCategory {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .riskCategoryHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .textArea {
      height: auto !important;
    }

    .btnSaveChanges {
      width: 190px !important;
    }

    .btnCancel {
      width: 190px;
      margin: 20px 10px;
      height: 50px !important;
    }

    .riskCategoryMainInfo {
      padding: 40px;
    }

    .innerTable {
      padding: 1.25rem;
      vertical-align: top;
      font-size: 15px;
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 0px !important;

      thead th {
        padding-bottom: 5px;
        background-color: $border;
        border: 1px solid $headerBorder;
        color: $textColor !important;
        font-weight: 500;
        height: 40px;
        vertical-align: middle !important;
        padding-top: 5px !important;
      }

      tbody tr {
        border: 1px solid $border;
      }

      tr td:first-child {
        color: $textColor !important;
        font-weight: 500;
        padding-left: 30px;
        border-left: none;
      }
      tr td:last-child {
        border-right: none;
        border-radius: 0;
      }

      tbody tr td {
        border: 1px solid $border;
      }

      .btnCol {
        text-align: right;
      }
      .changeButton {
        margin-top: 10px;
        max-width: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .removeButton {
        margin-top: 10px;
        float: right;
        max-width: 100px;
        margin-right: 20px;
      }

      .noBorderBottom {
        border-bottom: none !important;
      }

      .textInput {
        width: 85%;
        margin-bottom: 0px;
      }
      .textRatingInput {
        width: 85%;
        margin-bottom: 0px;
      }

      .riskScoring {
        height: 70px;
        border: none;

        .ratingSpace {
          padding: 0px 5px;
        }

        .container {
          display: flex;
        }
        .fixed {
          width: 100px;
          margin: auto;
        }
        .fixedSmall {
          width: 20px;
          margin: auto;
          float: right;
        }
        .flexitem {
          margin: auto;
          float: right;
        }
      }

      .riskScoring td {
        color: $textColor !important;
        font-weight: 500;
        vertical-align: middle;
        border: 1px solid $border;
        padding-left: 10px;
      }
      tr:first-child {
        border-top-style: hidden;
      }
      tr:last-child {
        border-bottom-style: hidden;
      }
    }
  }
}

.riskCategoryContent {
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  background-color: $white;
  box-sizing: border-box;
  position: relative;

  .intro {
    @include font(3);
    margin-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }

  .borderBox {
    border: 1px solid $border;
    margin-left: 15px;
    margin-bottom: 40px;
  }

  .changeButton {
    margin-top: 10px;
    max-width: 100px;
    margin-right: 20px;
    margin-bottom: 20px;
    float: right;
  }
  .removeButton {
    margin-top: 10px;
    float: right;
    max-width: 100px;
    margin-right: 20px;
  }

  .btnDelete {
    float: right;
    height: 50px !important;
  }

  .error {
    @include font(2);
    color: $error !important;
  }

  .ddlError > div {
    border: 1px solid $error;
  }

  .formControl {
    margin-bottom: 0px;
  }

  .formControlError {
    border: 1px solid $error !important;
  }

  .spacedRow {
    padding: 35px 25px;
  }

  .newRow {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
  }
}

.riskCategoryList {
  width: 100%;
  @include font(2);

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .topAndSideOnlyRow {
    padding: 30px 30px 0px 30px;
  }
  .sideRow {
    padding: 0px 30px;
    padding-bottom: 30px;
  }

  .margin5 {
    margin: 0px 5px;
  }

  .addbutton {
    float: right;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }

  .consequnceInfo {
    padding-left: 50px;
    padding-bottom: 20px;
    @include font(1);

    .listStyling {
      list-style-type: disc;
    }
  }

  .innerTable {
    padding: 1.25rem;
    vertical-align: top;
    font-size: 15px;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 40px !important;
    font-weight: 500;

    thead th {
      padding-bottom: 5px;
      background-color: $border;
      border: 1px solid $headerBorder !important;
      color: $textColor !important;
      font-weight: 500;
      height: 40px;
      vertical-align: middle !important;
      padding-top: 5px !important;
    }

    thead th p {
      @include font(1);
    }

    .tdGreen {
      background-color: $success;
    }
    .tdYellow {
      background-color: $yellow;
    }
    .tdOrange {
      background-color: $alert;
    }
    .tdRed {
      background-color: $danger;
    }

    .normalItalicText {
      font-weight: normal;
      font-style: italic;
      @include font(1);
    }

    tbody tr {
      border: 1px solid $border;
    }

    tr td:first-child {
      color: $textColor !important;
      font-weight: 500;
      padding-left: 30px;
      text-align: left;
    }

    tr td:last-child {
      border-radius: 0;
    }
    tbody tr td {
      border: 1px solid $border;
    }

    .btnCol {
      text-align: right;
    }
    .changeButton {
      margin-top: 10px;
      max-width: 100px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .removeButton {
      margin-top: 10px;
      float: right;
      max-width: 100px;
      margin-right: 20px;
    }

    .textInput {
      width: 50%;
      margin-bottom: 0px;
    }

    .riskScoring {
      height: 70px;
      border: none;

      .ratingSpace {
        padding: 0px 4px;
      }
    }

    .riskScoring td {
      padding-left: 10px;
      color: $textColor !important;
      font-weight: 500;
      vertical-align: middle;
      border: 1px solid $border;
      text-align: center;
    }
  }

  .newRow {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
  // }
}
