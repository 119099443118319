@import '../../styles/variables';
@import '../../styles/global';

.checklistTasksTemplates {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    button {
      // @include font(3, 'bold');
      color: $lightBlue;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .addbutton {
      padding-top: 9px !important;
      width: 180px !important;
    }

    .addbuttonCol {
      text-align: right;
    }

    .circle {
      display: inline-flex;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      border: solid 1px $darkBlue;
      @include font(2, 'medium');
      color: $darkBlue !important;
      margin-top: 8px;
      margin-left: 10px;
    }

    .titleRows {
      padding-top: 30px;
      padding-left: 18px;
      padding-right: 15px;

      img {
        margin-right: 10px;
      }

      h4 {
        padding-top: 5px;
      }
      .link {
        cursor: pointer;
      }
    }

    .innerComponent {
      width: 100%;
      border-radius: 6px;
      box-sizing: border-box;
      position: relative;
      padding: 4px;

      .viewButton {
        float: right;
        width: 102px;
        margin-right: 20px;
      }
    }

    @media (max-width: 768px) {
    }
  }
}
