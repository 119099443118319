@import '../../styles/variables';
@import '../../styles/global';

.correctiveAction {
  width: 100%;
  @include font(2);
  .tabHeader {
    padding-bottom: 20px;
  }
  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .btnSaveChanges {
      width: 230px !important;
    }

    .btnCancel {
      width: 230px;
      margin: 0px 5px;
      height: 50px !important;
    }

    .margin5 {
      margin: 0px 5px;
    }

    .changeButton {
      width: 194px !important;
      float: right;
    }

    .colActionButton {
      float: right;
      margin-right: 0px;
      align-items: center;
    }

    .breakRow {
      padding: 30px 0px;
    }

    .correctiveActionHeader {
      justify-content: space-between;
      margin-bottom: 20px;

      .backLink {
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-bottom: 15px;
        display: block;
      }
    }

    .correctiveActionContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 30px;
      box-sizing: border-box;
      position: relative;
      margin-top: 40px;

      .intro {
        @include font(3);
        margin-bottom: 20px;
      }

      .margin5 {
        margin: 0px 5px;
      }

      .borderBox {
        border: 1px solid $border;
        margin-left: 15px;
        margin-bottom: 40px;
      }
      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }

      .viewRow {
        padding-top: 20px !important ;
      }
    }

    .innerTable {
      margin-top: -20px;

      .buttonContainer {
        display: flex;
        justify-content: flex-end;
        .removeButton {
          float: right;
          width: 100px;
          margin-right: 20px;
        }
      }
      .dot {
        height: 12px;
        width: 12px;
        background-color: $danger;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
      .dotActive {
        background-color: $danger;
      }
      .dotComplete {
        background-color: $success;
      }
      .status {
        text-transform: capitalize;
      }
      .active {
        border-left: solid 6px $danger !important;
      }
      .complete {
        border-left: solid 6px $success !important;
      }
    }

    .emergencyPlanItemContent {
      width: 100%;
      border-radius: 5px;
      // background-color: $white;
      box-sizing: border-box;
      position: relative;
      margin-top: 50px;
      // padding-bottom: 30px;

      .btnMedium {
        width: 150px !important;
        height: 36px !important;
        float: right;
      }

      .addbutton {
        width: 194px !important;
        float: right;
      }

      .subheading {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        border: solid 1px $darkBlue;
        @include font(2, 'medium');
        color: $darkBlue !important;
        margin-top: 8px;
        margin-left: 10px;
      }

      .error {
        @include font(2);
        color: $error !important;
      }

      .ddlError > div {
        border: 1px solid $error;
      }

      .formControl {
        margin-bottom: 0px;
      }

      .formControlSmall {
        margin-bottom: 0px;
        max-width: 90px;
      }

      .formControlError {
        border: 1px solid $error !important;
      }

      .textArea {
        min-height: 100px;
        height: auto !important;
      }

      .wsaDropdown {
        width: 100%;
        padding-bottom: 10px;

        div[class*='control'] {
          height: 48px;
        }

        div[class*='menu-list'] {
          div[class*='option'] {
            border-bottom: solid 1px $border;
          }
        }

        span[class*='indicatorSeparator'] {
          background-color: $white;
        }
      }

      .contentHolder {
        padding: 30px;

        .topRow {
          padding-bottom: 30px;
        }
      }

      .grpItem {
        margin-bottom: 30px;
      }

      .grpHeading {
        padding-left: 30px;
        padding-right: 30px;
      }
      .newRow {
        padding: 30px;
      }

      @media (max-width: 768px) {
        .addbutton {
          width: 100% !important;
        }
      }
    }
  }
}

.btnSaveChangesSmall {
  width: 100px !important;
  height: 36px !important;
}

.btnCancelSmall {
  width: 100px;
  margin: 0px 5px;
  height: 36px !important;
}

.correctiveActionList {
  width: 100%;
  @include font(2);

  .contentWrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .textHeading {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
  }

  .tabHeader {
    padding-bottom: 20px;
  }

  .margin5 {
    margin: 0px 5px;
  }

  .addbutton {
    float: right;
  }
  .changeButton {
    float: right;
    height: 35px;
    width: 100px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .sortIcon {
    height: 12.2px;
    width: 10px;
    margin-left: 8px;
  }
  .sortHeading {
    cursor: pointer;
  }

  @media (max-width: 992px) {
    .addbutton {
      width: 100% !important;
    }
    .changeButton {
      float: right !important;
      width: 50px !important;
      font-size: 10px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}
